$(function() {
    $("body").show();
    
    $("header").delay(300).animate({top:0}, 200, function(){
        $("#mobile-navigation").animate({opacity: 1});
        $("#page-loader").delay(450).fadeOut();
    });
});


function toggleHeroDetail() {
    $('body').toggleClass('hero-detail-open');
}